<template>
  <div>
    <v-row v-if="next_page!=='confirmar_reserva'">
      <v-col>
        <v-btn class="pl-1 pr-1 white--text" x-large color="degrade-background" @click.prevent="go_to_centros" block>
          {{ $t('Comenzar reserva sin cuenta') }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row v-else-if="next_page==='confirmar_reserva'">
      <v-col>
        <v-alert
            type="primary"
            icon="mdi-alert-decagram"
        >
          {{ $t('¡Atención!Para finalizar tu reserva debes iniciar sesión o regístrarte') }}.
        </v-alert>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-alert
            type="secondary"
            icon="mdi-alert-decagram"
        >
          {{ $t('Si ya eres usuario de la App Elha, inicia sesión con tu email y contraseña') }} 👇🏻
        </v-alert>
        <p class="text-center">{{ $t('Inicia sesión') }}</p>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-form ref="form" v-model="form_valid">
          <v-row>
            <v-col class="pt-0 pb-0">
              <v-text-field v-model="email" :rules="email_rules" :label="$t('Email')" required></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col class="pt-0 pb-0">
              <v-text-field
                  v-model="password"
                  :counter="6"
                  :rules="password_rules"
                  :label="$t('Password')"
                  type="password"
                  required
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col class="pt-0 pb-0">
              <RecoverPassword
                  ref="recover_password"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-btn :disabled="!form_valid" color="primary" @click.prevent="login" block>{{ $t('Entrar') }}</v-btn>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <Registro
                  ref="registro"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <div class="mt-5">
          <img style="max-width:200px" class="mx-auto d-block" src="../assets/logo-elha.png" alt="logo-elha">
        </div>
      </v-col>
    </v-row>

  </div>

</template>

<script>
import send_stats_mixin from "@/mixins/send_stats_mixin";

export default {
  name: 'Login',
  mixins: [send_stats_mixin],
  data() {
    return {
      form_valid: true,
      email: '',
      email_rules: [
        v => !!v || this.$t('El email es obligatorio'),
        v => /.+@.+/.test(v) || this.$t('El email debe ser válido')
      ],
      password: '',
      password_rules: [
        v => !!v || this.$t('El password es obligatorio'),
        v => (v && v.length >= 6) || this.$t('El password debe tener al menos 6 caracteres')
      ],

      next_page: 'confirmar_reserva'
    }
  },

  methods: {
    async login() {
      if (this.$refs.form.validate()) {
        let user = await this.$store.dispatch('auth/login', {
          email: this.email,
          password: this.password
        })
        if (user) {
          this.$notify({
            title: this.$t('Acción realizada correctamente'),
            text: this.$t('Login correcto')
          })
          // Contamos las citas pendientes
          let reservas_pendientes = 0
          user.reservas_usuario_portal.forEach(
              current_reserva => {
                if (current_reserva.estado.id === 2) {
                  ++reservas_pendientes
                }
              }
          )
          if (reservas_pendientes > 0) {
            const resp = await this.$alert(
                this.$t('Para poder realizar una nueva reserva, primero tienes que anular las citas pendientes.'),
                '',
                'info'
            )
            await this.$router.replace({name: "reservas"})
          } else {
            setTimeout(() => {
              this.$router.push({name: this.next_page})
            }, 1500)
          }
        } else {
          this.$notify({
            title: this.$t('Error'),
            text: this.$t('Login incorrecto'),
            style: 'error'
          })
        }
      }
    },

    go_to_centros() {
      this.$router.push({name: "centros"})
    }
  },

  mounted() {
    if (this.$route.query.next) {
      this.next_page = this.$route.query.next
    }

    if (this.$route.query.action === 'signup') {
      this.next_page = this.$route.query.action
      setTimeout(() => {
        this.$refs.registro.dialog = true
      }, 500)
    }

    if (this.$route.query.action === 'recover_password') {
      this.next_page = this.$route.query.action
      setTimeout(() => {
        this.$refs.recover_password.dialog = true
      }, 500)
    }
  },

  components: {
    Registro: () => import('@/components/Registro'),
    RecoverPassword: () => import('@/components/RecoverPassword')
  }
}
</script>
